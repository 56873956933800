.carousel {
  max-width: calc(100vw - 20rem);
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  padding: 1.5rem;
}

.card {
  background-color: var(--white);
  width: 33.33%;
  min-width: 325px;
  max-width: 350px;
  padding: 2rem;
  border-radius: var(--border-radius);
  border: var(--border);
  cursor: pointer;
}

.card:not(:last-child) {
  margin-right: 2rem;
}

.card:hover {
  box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
}

.card-title {
  font-size: 1.05rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--very-dark-grey);
}

.card-list {
  list-style-type: none;
}

.card-list-item {
  color: var(--dark-grey);
}

.card-list-item:not(:last-child) {
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .carousel {
    margin-top: 0;
    max-width: calc(100vw - 4rem);
  }
}
