footer {
  background-color: var(--very-dark-grey);
  background-image: url("../assets/images/footer-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: soft-light;
  color: var(--white);
  padding: 0 0 1rem;
}

.column-flex {
  display: flex;
  flex-direction: column;
  font-size: 1.15rem;
}

.column-flex:first-child {
  text-align: center;
}
.column-flex:last-child {
  text-align: center;
}

.footer-list-title {
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
}

.footer-link {
  color: var(--white);
  text-decoration: none;
  background-color: transparent;
}

.footer-link:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.footer-link:not(:last-child) {
  margin-bottom: 1rem;
}

.footer-top {
  padding: 5rem 10rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-logo {
  width: 110px;
  height: 110px;
  align-self: center;
}

.footer-bottom-text {
  text-align: center;
  letter-spacing: 0.15rem;
  font-weight: 600;
  /* padding: 2rem; */
}
.footer-bottom-text.light {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.705);
}

@media screen and (max-width: 768px) {
  .footer-top {
    padding: 5rem 2rem;
  }
}
@media screen and (max-width: 500px) {
  .footer-logo {
    display: none;
  }
  .column-flex:first-child {
    text-align: left;
  }
  .column-flex:last-child {
    text-align: right;
  }
  .footer-list-title {
    text-align: center;
  }
}
