header {
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  z-index: 9999;
}

.logo {
  width: 3rem;
  height: 50px;
  display: grid;
  place-items: center;
  filter: saturate(0) brightness(100);
}

.nav-link {
  margin-right: 1.75rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  color: rgb(255, 255, 255);
  transition: color 0.2s ease-in-out;
}

.active-nav-link {
  color: var(--primary-blue);
  font-weight: 700;
}

.menu-toggle-button {
  color: var(--white);
  font-size: 1.5rem;
  display: none;
}

@media screen and (max-width: 599px) {
  nav {
    position: absolute;
    width: 100%;
    height: calc(100vh - 4rem);
    top: 4rem;
    background-color: var(--very-dark-grey);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    margin-left: -2rem;
    transform: translateX(-100%);
    transition: transform 0.2s linear;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  .nav-link {
    font-size: 1.15rem;
    letter-spacing: 0.15rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }
  .menu-toggle-button {
    display: block;
  }
  .menu-open {
    transform: translateX(0);
  }
}
