.contact-link {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.contact-link a {
  color: var(--dark-grey);
}

.contact-link svg {
  margin-right: 1rem;
}

#contact .section-illustration {
  width: 120%;
}

#contact .right {
  display: flex;
  flex-direction: column;
  align-items: center;
}
