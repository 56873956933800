:root {
  --primary-blue: #02b9e3;
  --very-dark-grey: #1d2d33;
  --dark-grey: #344a53;
  --light-grey: #97afb9;
  --neutral-grey: #d6d6d6;
  --highlight-blue: #8dffff;
  --white: #ffffff;
  --border: 0.5px solid var(--neutral-grey);
  --border-radius: 0.5rem;
  --box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.14);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "PT Sans", "Helvetica", sans-serif;
  transition: box-shadow 0.125s linear;
}

.float-icon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: grid;
  place-items: center;
  position: fixed;
  font-size: 1.5rem;
  color: var(--white);
  background-color: #25d366;
  z-index: 9999;
  bottom: 2rem;
  right: 4rem;
  transition: transform 0.2s linear;
}

.float-icon:hover {
  transform: scale(1.05);
}

.section-heading,
.card-title {
  font-family: "Archivo";
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

body {
  width: 100%;
}

section.content-container {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  align-items: center;
  position: relative;
}

section.content-container:not(:first-child) {
  padding-top: calc(50px + 1rem);
  z-index: 1;
  background-color: #fff;
  background-image: url("../assets/images/wave.svg");
  background-repeat: no-repeat;
  background-position: 0 100%;
  /* background-blend-mode: screen; */
}

section.content-container:nth-child(odd):not(:first-child) {
  background-color: rgb(246, 249, 255);
}

section.content-container:nth-child(even):not(:first-child) {
  background-color: var(--white);
}

.hero {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  position: fixed;
  background-image: url("../assets/images/hero.jpg");
  background-size: cover;
  background-origin: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: -1;
}

.hero:before {
  content: "";
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.hero div {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-logo {
  min-width: 220px;
  max-width: 275px;
  width: 30vw;
}

.tagline {
  font-size: 1.75rem;
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-weight: 500;
  margin-top: 1.5rem;
  letter-spacing: 0.25rem;
  text-align: center;
}

/* section styling */

.two-section-grid {
  display: grid;
  grid-template-columns: 45% 45%;
  align-items: center;
  column-gap: 5rem;
}

.flex-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center-area {
  width: 100%;
  max-width: 1500px;
  padding: 0 10rem 5rem 10rem;
  position: relative;
  /* overflow-x: scroll; */
}

.section-heading {
  font-weight: 700;
  font-size: 2.25rem;
  position: relative;
  margin-bottom: 2rem;
  text-align: left;
}

.section-heading::after {
  content: "";
  width: 5rem;
  height: 3px;
  position: absolute;
  left: -10px;
  bottom: -5px;
  background-color: var(--primary-blue);
  animation: underline 2s infinite;
}

.section-text-content {
  text-align: justify;
  font-size: 1.15rem;
  line-height: 1.75rem;
  color: var(--dark-grey);
}

.section-illustration {
  width: 100%;
}

a.next-section {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: var(--primary-blue);
  display: grid;
  place-items: center;
  bottom: 2rem;
  left: 50%;
  color: var(--white);
}

#pp {
  margin-top: 2rem;
}

.sub-heading {
  color: var(--very-dark-grey);
  margin-bottom: 0.5rem;
}

#pp .text {
  color: var(--dark-grey);
  text-align: justify;
  font-size: 1.05rem;
  margin-bottom: 1.5rem;
}

/* scroll bar */

*::-webkit-scrollbar {
  display: none;
}
* {
  scrollbar-width: none;
}

/* scroll bar */

@media screen and (max-width: 768px) {
  .flex-area {
    align-items: center;
    justify-content: start;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .center-area {
    height: 100%;
    padding: 5rem 2rem 5rem 2rem;
  }
  .carousel {
    width: 500px;
  }
  .card {
    width: 100%;
  }
  .footer-top {
    padding: 5rem 2rem;
  }
  .section-heading {
    margin-bottom: 2rem;
  }
  a.next-section {
    font-size: 1.35rem;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    left: calc(50% - 27.5px);
  }
  #pp {
    margin-top: 0;
  }
  .float-icon {
    bottom: 1.5rem;
    right: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .center-area {
    padding: 5rem 1.5rem 7rem 1.5rem;
  }
  .carousel {
    max-width: calc(100vw - 1rem);
  }
}

/* keyframes */
@keyframes underline {
  0% {
    width: 2px;
  }
  50% {
    width: 5rem;
  }
  100% {
    width: 2px;
  }
}

@media screen and (max-width: 1050px) {
  .section-illustration {
    display: none;
  }
}
