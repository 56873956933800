.list-view-container {
  padding: 1rem;
}

.list-view-item-container:not(:last-child) {
  margin-bottom: 1rem;
}

.list-view-item-title {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  background-color: var(--white);
  border: var(--border);
  padding: 1rem 1.5rem;
  font-size: 1.05rem;
  font-weight: 600;
  color: var(--dark-grey);
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
}

.list-view-item-title:hover {
  box-shadow: var(--box-shadow);
}

.active.drop-down {
  transform: rotate(180deg);
}

.list-view-item-desc {
  display: none;
  padding: 0 2rem;
}

.active.list-view-item-desc {
  display: block;
}

.list-view-sublist-item {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--border);
  color: var(--dark-grey);
}

.enroll-btn {
  background-color: var(--dark-grey);
  padding: 0.5rem 1rem;
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  outline: none;
  border: none;
  border-radius: 4px;
}

.enroll-btn:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

@media screen and (max-width: 599px) {
  .list-view-sublist-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .enroll-btn {
    align-self: flex-end;
  }
}
